import { useStore } from '@nanostores/preact'
import classNames from 'utils/preact/class-names'

import { Currency, IsoCountry, ProductDuration, Products } from './support'
import { useTranslation } from './translation'
import { profile } from './store'
import css from './style.module.scss'

export function AuthenticatedSubscribe({
  products,
  currencies,
  isoCountries,
  onFree,
  onPaid
}: {
  token: string
  products: Products
  currencies: Currency[]
  isoCountries: IsoCountry[]
  onFree: () => void
  onPaid: () => void
}) {
  const t = useTranslation()
  const user = useStore(profile)
  const country = isoCountries.find((c) => c.iso === user.countryIso)
  const currency = currencies.find((c) => c.acronym === country.currency)

  return (
    <div className={css.content}>
      <h2
        className={classNames({
          [css.title]: true,
          [css['spacer-bottom']]: true
        })}
      >
        {t('support_us_condensed')}{' '}
        <span className={css['secondary-highlight']}>({t('app')})</span>
      </h2>
      <p
        className={classNames({
          [css['text-small']]: true,
          [css['font-medium']]: true
        })}
      >
        {t('support_text')}
      </p>
      <p
        className={classNames({
          [css['text-small']]: true,
          [css['text-highlight']]: true
        })}
      >
        {t('contributing_will_remove_adverts')}
      </p>
      <ul
        className={classNames({
          [css.list]: true,
          [css['spacer-bottom']]: true
        })}
      >
        <li className={css.item}>{t('faster_page_loading')}</li>
        <li className={css.item}>{t('app_access')}</li>
      </ul>

      {['monthly', 'annually'].map((type: ProductDuration) => {
        const price = products[type].prices[currency.acronym]

        return (
          <label
            className={classNames({
              [css['is-active']]: user.subscription === type,
              [css['radio-label']]: true,
              [css['radio-label-primary']]: true
            })}
            htmlFor={`subscription-${type}`}
          >
            <div className={css['subscription-label']}>
              <span className={css['subscription-name']}>{t(`support_${type}`)}</span>
              <span className={css['subscription-price']}>
                {currency.prefix}&thinsp;{price.unit_amount / 100}
              </span>
            </div>
            <input
              type="radio"
              name="subscription"
              value={type}
              className={css['radio-input']}
              id={`subscription-${type}`}
              onChange={() => profile.setKey('subscription', type)}
            />
          </label>
        )
      })}

      <p
        className={classNames({
          [css['text-small']]: true,
          [css['font-medium']]: true
        })}
      >
        {t('cant_support_now')}
      </p>
      <label
        className={classNames({
          [css['is-active']]: user.subscription === 'free',
          [css['radio-label']]: true,
          [css['radio-label-secondary']]: true
        })}
        htmlFor="subscription-free"
      >
        <div>{t('continue_free')}</div>
        <input
          type="radio"
          name="subscription"
          value="free"
          className={css['radio-input']}
          id="subscription-free"
          onChange={() => profile.setKey('subscription', 'free')}
        />
      </label>

      <button
        className={css.submit}
        type="submit"
        onClick={() => {
          if (user.subscription === 'free') onFree()
          else onPaid()
        }}
      >
        {t('continue')}
      </button>

      {user.subscription !== 'free' && (
        <p
          className={classNames({
            [css['text-tiny']]: true,
            [css['font-italic']]: true,
            [css['text-center']]: true
          })}
        >
          {t(`your_${user.subscription}_contribution_will`)}
        </p>
      )}

      <p
        className={classNames({
          [css['text-tiny']]: true,
          [css['font-medium']]: true,
          [css['text-center']]: true
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(
            'agree_to_terms',
            (v) => `<a href="/pages/privacy_policy" target="_blank">${v}</a>`
          )
        }}
      />
    </div>
  )
}
