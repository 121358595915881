import throttle from 'utils/throttle'

import './leaflet.scss'
import './static/placeholder-icon.svg'
import './static/placeholder.jpg'
import { BaseComponent } from 'utils/base-component'
import { SurfMapConfig } from './surf-map-config'

export class Leaflet extends BaseComponent<SurfMapConfig[]> {
  static cssClass = 'leaflet'

  scrollListener?: () => void
  clickListener?: () => void

  mapConfig!: SurfMapConfig

  init() {
    const mapIdStr = this.root.dataset.mapId
    if (mapIdStr === undefined) {
      // eslint-disable-next-line no-console
      console.warn('leaflet map: id is not provided')
      return
    }

    const mapId = parseInt(mapIdStr, 10)
    if (this.config[mapId] === undefined) {
      // eslint-disable-next-line no-console
      console.warn(`leaflet map: config is not provided for id ${mapId}`)
      return
    }

    this.mapConfig = this.config[mapId]

    if (this.mapConfig.loadingStrategy === 'common') {
      this.commonLoadingStrategy()
    } else {
      this.instantLoadOnMobile()
    }
  }

  commonLoadingStrategy() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      this.scrollListener = throttle(() => {
        this.loadMapIfInViewport()
      }, 500)

      window.addEventListener('scroll', this.scrollListener)
      this.loadMapIfInViewport()
    } else {
      this.clickListener = this.loadMapOnClick.bind(this)
      this.root.addEventListener('click', this.clickListener)
    }
  }

  instantLoadOnMobile() {
    this.scrollListener = throttle(() => {
      this.loadMapIfInViewport()
    }, 500)

    window.addEventListener('scroll', this.scrollListener)
    this.loadMapIfInViewport()
  }

  loadMapOnClick() {
    if (this.clickListener) {
      this.root.removeEventListener('click', this.clickListener)
      this.clickListener = undefined
    }

    this.chunk()
  }

  loadMapIfInViewport() {
    if (this.inViewport()) {
      if (this.scrollListener) {
        window.removeEventListener('scroll', this.scrollListener)
        this.scrollListener = undefined
      }

      this.chunk()
    }
  }

  chunk() {
    this.element('pulse-animation')?.toggleModifier('loading', true)
    this.root.classList.add(`${this.cssClass}--initial-loading`)

    import(/* webpackChunkName: 'leaflet' */ './surf-map').then((module) => {
      // eslint-disable-next-line
      new module.default(this.root, this.mapConfig)
    })
  }

  inViewport() {
    const bounding = this.root.getBoundingClientRect()
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight

    return (
      (bounding.top >= 0 && bounding.top <= viewportHeight) ||
      (bounding.bottom >= 0 && bounding.top <= viewportHeight)
    )
  }
}
