import { FormApi } from 'final-form'
import { Ref } from 'preact/hooks'

export function FocusFirstError(ref: Ref<HTMLElement>) {
  return function decorator(form: FormApi) {
    const fields = Array.from(ref.current.querySelectorAll('input'))
    const unsubscribe = form.subscribe(
      (state) => {
        if (!ref.current) return

        if (state.hasSubmitErrors) {
          const firstErrorField = Object.keys(state.submitErrors)[0]
          const field = fields.find((input) => input.name === firstErrorField)
          if (!field) return
          field.focus()
        }
      },
      {
        hasSubmitErrors: true,
        submitErrors: true
      }
    )

    return unsubscribe
  }
}
