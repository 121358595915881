import { useStore } from '@nanostores/preact'
import { useMemo, useRef, useState } from 'preact/hooks'
import { FormState, FORM_ERROR } from 'final-form'
import { Form, Field, FormSpy, FormRenderProps } from 'react-final-form'

import classNames from 'utils/preact/class-names'

import { send } from './services/backend'
import { checkEmail, checkPassword, checkPasswordConfirm } from './services/validation'
import { useTranslation } from './translation'
import { profile, SubscriptionValue } from './store'
import { Currency, IsoCountry, ProductDuration, Products } from './support'
import { FocusFirstError } from './focus-first-error'

import css from './style.module.scss'
import eye from './static/eye.svg'
import eyeSlash from './static/eye-slash.svg'

interface FormValues {
  email: string
  isoCountry: string
  password: string
  passwordConfirm: string
  subscription: SubscriptionValue
}

export function Authorization(props: {
  token: string
  products: Products
  currencies: Currency[]
  isoCountries: IsoCountry[]
  onAuthenticate: () => void
  onFree: () => void
  onPaid: () => void
}) {
  const t = useTranslation()
  const user = useStore(profile)
  const formRef = useRef<HTMLFormElement>(null)
  const decorator = useMemo(() => FocusFirstError(formRef), [])
  const [isInitialized, setIsInitialized] = useState(false)
  const [passwordHidden, setPasswordHidden] = useState(true)
  const wrapHighlight = (v: string) => `<span class=${css['text-highlight']}>${v}</span>`

  return (
    <div className={css.content}>
      <Form
        initialValues={{
          isoCountry: user.countryIso
        }}
        decorators={[decorator]}
        onSubmit={(values: FormValues): Promise<Record<string, string> | undefined> => {
          let url: string
          let method: string

          if (user.newAccountCreated) {
            url = '/user'
            method = 'put'
          } else {
            url = '/users/create_with_confirmation'
            method = 'post'
          }

          return send<FormValues & { source: string }>({
            url,
            method,
            body: JSON.stringify({ user: { ...values, source: user.source } }),
            token: props.token
          }).then((result) => {
            if (result.success) return undefined
            if (result.error) return { [FORM_ERROR]: result.error }
            return result.errors
          })
        }}
      >
        {({
          handleSubmit,
          pristine,
          submitting,
          submitError,
          hasSubmitErrors,
          hasValidationErrors,
          values,
          form
        }: FormRenderProps & FormState<FormValues>) => (
          <form
            action="/users"
            method="post"
            onSubmit={handleSubmit}
            ref={formRef}
            className={css['split-container-reverse']}
          >
            <FormSpy
              subscription={{ pristine: true }}
              onChange={() => {
                if (!isInitialized) {
                  form.batch(() => {
                    form.change('email', user.email)
                    form.change('password', user.password)
                    form.change('subscription', user.subscription)
                    form.change('passwordConfirm', user.passwordConfirm)
                    form.change('isoCountry', user.countryIso)
                  })
                  setIsInitialized(true)
                }
              }}
            />
            <div className={css['flex-grow']}>
              <h2
                className={classNames({
                  [css.title]: true,
                  [css['spacer-bottom']]: true,
                  [css['large-up-hidden']]: true
                })}
              >
                {t('join_sfc')}
              </h2>
              <h2
                className={classNames({
                  [css.title]: true,
                  [css['spacer-bottom']]: true,
                  [css['large-up-shown']]: true
                })}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('join_for_free_to', wrapHighlight, wrapHighlight)
                }}
              />
              <p
                className={classNames({
                  [css['text-small']]: true,
                  [css['font-regular']]: true
                })}
              >
                {t('already_have_account')}{' '}
                <a
                  className={classNames({
                    [css['text-highlight']]: true,
                    [css['text-underline']]: true
                  })}
                  href="/login"
                  onClick={(event: MouseEvent) => {
                    event.preventDefault()
                    props.onAuthenticate()
                  }}
                >
                  {t('log_in')}
                </a>
              </p>
              <p
                className={classNames({
                  [css['text-medium']]: true,
                  [css['font-medium']]: true,
                  [css['large-up-hidden']]: true
                })}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('join_for_free_to', wrapHighlight, wrapHighlight)
                }}
              />

              <FormSpy
                subscription={{ submitSucceeded: true }}
                onChange={({ submitSucceeded }: { submitSucceeded: boolean }) => {
                  if (submitSucceeded) {
                    profile.setKey('newAccountCreated', true)
                    profile.setKey('reloadRequired', true)
                    profile.setKey('authorized', true)

                    profile.setKey('email', values.email)
                    profile.setKey('password', values.password)
                    profile.setKey('passwordConfirm', values.passwordConfirm)
                    profile.setKey('subscription', values.subscription)
                    profile.setKey('countryIso', values.isoCountry)

                    if (values.subscription === 'free') props.onFree()
                    else props.onPaid()
                  }
                }}
              />
              <Field
                name="email"
                validate={(value: string) => checkEmail(t, value)}
                render={({ input, meta }) => (
                  <label
                    className={classNames({
                      [css.label]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                    htmlFor="email"
                  >
                    <div>{t('your_email')}</div>
                    <div
                      className={classNames({
                        [css['input-wrapper']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      <input
                        {...input}
                        id="email"
                        type="email"
                        autoComplete="email"
                        className={classNames({
                          [css.input]: true,
                          [css['is-valid']]: meta.touched && meta.valid,
                          [css['is-invalid']]: meta.touched && meta.invalid
                        })}
                      />
                    </div>
                    <div className={css.error}>
                      {(meta.touched && meta.error) || meta.submitError}
                    </div>
                  </label>
                )}
              />

              <Field
                name="isoCountry"
                render={({ input, meta }) => (
                  <label
                    className={classNames({
                      [css.label]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                    htmlFor="isoCountry"
                  >
                    <div>{t('country')}</div>
                    <select
                      {...input}
                      id="isoCountry"
                      className={classNames({
                        [css.input]: true,
                        [css['is-dropdown']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      {props.isoCountries.map((isoCountry) => (
                        <option value={isoCountry.iso}>{isoCountry.name}</option>
                      ))}
                    </select>
                    <div className={css.error}>
                      {(meta.touched && meta.error) || meta.submitError}
                    </div>
                  </label>
                )}
              />

              <Field
                name="password"
                validate={(value: string) => checkPassword(t, value)}
                render={({ input, meta }) => (
                  <label
                    className={classNames({
                      [css.label]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                    htmlFor="password"
                  >
                    <div className={css['password-field-label']}>
                      <span>{t('password')}</span>
                      <button
                        type="button"
                        className={css['password-field-toggle']}
                        onClick={() => setPasswordHidden((value) => !value)}
                      >
                        <img
                          src={passwordHidden ? eye : eyeSlash}
                          alt={passwordHidden ? 'Show password' : 'Hide password'}
                        />
                      </button>
                    </div>
                    <div
                      className={classNames({
                        [css['input-wrapper']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      <input
                        {...input}
                        id="password"
                        type={passwordHidden ? 'password' : 'text'}
                        autoComplete="new-password"
                        className={classNames({
                          [css.input]: true,
                          [css['is-valid']]: meta.touched && meta.valid,
                          [css['is-invalid']]: meta.touched && meta.invalid
                        })}
                      />
                    </div>
                    <div className={css.error}>
                      {(meta.touched && meta.error) || meta.submitError}
                    </div>
                  </label>
                )}
              />
              <Field
                name="passwordConfirm"
                validate={(value: string, { password }: FormValues) =>
                  checkPasswordConfirm(t, value, password)
                }
                render={({ input, meta }) => (
                  <label
                    className={classNames({
                      [css.label]: true,
                      [css['is-valid']]: meta.touched && meta.valid,
                      [css['is-invalid']]: meta.touched && meta.invalid
                    })}
                    htmlFor="passwordConfirm"
                  >
                    <div>{t('repeat_password')}</div>
                    <div
                      className={classNames({
                        [css['input-wrapper']]: true,
                        [css['is-valid']]: meta.touched && meta.valid,
                        [css['is-invalid']]: meta.touched && meta.invalid
                      })}
                    >
                      <input
                        {...input}
                        id="passwordConfirm"
                        type={passwordHidden ? 'password' : 'text'}
                        autoComplete="new-password"
                        className={classNames({
                          [css.input]: true,
                          [css['is-valid']]: meta.touched && meta.valid,
                          [css['is-invalid']]: meta.touched && meta.invalid
                        })}
                      />
                    </div>
                    <div className={css.error}>
                      {(meta.touched && meta.error) || meta.submitError}
                    </div>
                  </label>
                )}
              />

              <div className={css['spacer-bottom']} />

              <button
                className={classNames({
                  [css.submit]: true,
                  [css['is-loading']]: submitting,
                  [css['large-up-shown']]: true
                })}
                type="submit"
                disabled={pristine || hasValidationErrors}
              >
                Create Account
              </button>

              <p
                className={classNames({
                  [css['text-tiny']]: true,
                  [css['font-regular']]: true,
                  [css['text-center']]: true,
                  [css['large-up-shown']]: true
                })}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t(
                    'agree_to_terms',
                    (v) => `<a href="/pages/privacy_policy" target="_blank">${v}</a>`
                  )
                }}
              />
            </div>

            <div className={css['horizontal-divider']} />

            <div className={css['flex-grow']}>
              <h2 className={css.title}>
                {t('support_us_condensed')}&nbsp;
                <span
                  className={classNames({
                    [css.subtitle]: true,
                    [css['spacer-bottom']]: true,
                    [css['inline-block']]: true
                  })}
                >
                  ({t('app')})
                </span>
              </h2>
              <p
                className={classNames({
                  [css['text-small']]: true,
                  [css['font-regular']]: true
                })}
              >
                {t('support_vital')}
              </p>
              <p
                className={classNames({
                  [css['text-small']]: true,
                  [css['text-highlight']]: true
                })}
              >
                {t('contributing_will_remove_adverts')}
              </p>
              <ul
                className={classNames({
                  [css.list]: true,
                  [css['spacer-bottom']]: true
                })}
              >
                <li className={css.item}>{t('faster_page_loading')}</li>
                <li className={css.item}>{t('app_access')}</li>
              </ul>

              {['monthly', 'annually'].map((type: ProductDuration) => {
                const country = props.isoCountries.find((c) => c.iso === values.isoCountry)
                const currency = props.currencies.find((c) => c.acronym === country.currency)
                const price = props.products[type].prices[currency.acronym]

                return (
                  <Field
                    name="subscription"
                    type="radio"
                    value={type}
                    render={({ input }) => (
                      <label
                        className={classNames({
                          [css['is-active']]: input.checked,
                          [css['radio-label']]: true,
                          [css['radio-label-primary']]: true
                        })}
                        htmlFor={`subscription-${type}`}
                      >
                        <div className={css['subscription-label']}>
                          <span className={css['subscription-name']}>{t(`support_${type}`)}</span>
                          <span className={css['subscription-price']}>
                            {currency.prefix}&thinsp;{price.unit_amount / 100}
                          </span>
                        </div>
                        <input
                          {...input}
                          className={css['radio-input']}
                          id={`subscription-${type}`}
                        />
                      </label>
                    )}
                  />
                )
              })}

              <p
                className={classNames({
                  [css['text-small']]: true,
                  [css['font-regular']]: true
                })}
              >
                {t('cant_support_now')}
              </p>
              <Field
                name="subscription"
                type="radio"
                value="free"
                render={({ input }) => (
                  <label
                    className={classNames({
                      [css['is-active']]: input.checked,
                      [css['radio-label']]: true,
                      [css['radio-label-secondary']]: true
                    })}
                    htmlFor="subscription-free"
                  >
                    <div>{t('continue_free')}</div>
                    <input {...input} className={css['radio-input']} id="subscription-free" />
                  </label>
                )}
              />

              {hasSubmitErrors && (
                <p
                  className={classNames({
                    [css['text-medium']]: true,
                    [css['text-center']]: true,
                    [css['error-highlight']]: true
                  })}
                >
                  {submitError}
                </p>
              )}
              <button
                className={classNames({
                  [css.submit]: true,
                  [css['is-loading']]: submitting,
                  [css['large-up-hidden']]: true
                })}
                type="submit"
                disabled={pristine || hasValidationErrors}
              >
                {t('continue')}
              </button>

              {values.subscription !== 'free' && (
                <p
                  className={classNames({
                    [css['text-tiny']]: true,
                    [css['font-italic']]: true,
                    [css['text-center']]: true
                  })}
                >
                  {t(`your_${values.subscription}_contribution_will`)}
                </p>
              )}
              <p
                className={classNames({
                  [css['text-tiny']]: true,
                  [css['font-regular']]: true,
                  [css['text-center']]: true,
                  [css['large-up-hidden']]: true
                })}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t(
                    'agree_to_terms',
                    (v) => `<a href="/pages/privacy_policy" target="_blank">${v}</a>`
                  )
                }}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}
