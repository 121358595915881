import { Support } from '../support'

export async function getSupport(
  onNetworkError: (msg: string) => void,
  onSyntaxError: (msg: string) => void,
  onSuccess: (sup: Support) => void
) {
  let res: Response
  try {
    res = await fetch('/sign_up/support')
  } catch (error) {
    let message = "Couldn't indentify network error"
    if (error instanceof Error) message = `${error.name}: ${error.message}`

    onNetworkError(message)
    return
  }

  if (!res.ok) {
    onNetworkError(`${res.status}: ${res.statusText}`)
    return
  }

  let json: Support
  try {
    json = await res.json()
  } catch (error) {
    let message = "Couldn't indentify syntax error"
    if (error instanceof SyntaxError) message = `${error.name}: ${error.message}`

    onSyntaxError(message)
    return
  }

  onSuccess(json)
}

export async function send<T extends Object>({
  url,
  body,
  token,
  method = 'post'
}: {
  url: string
  body: string
  token: string
  method?: string
}): Promise<{ error?: string; errors?: { [key in keyof T]?: string }; success?: boolean }> {
  let res: Response
  try {
    res = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': token
      },
      credentials: 'include',
      body
    })
  } catch (error) {
    let message = 'Something went wrong. Please, try again or reload the page.'
    if (error instanceof Error) message += ` (${error.name}: ${error.message})`

    return { error: message }
  }

  try {
    const json = await res.json()
    if (Object.hasOwnProperty.call(json, 'success')) return { success: json.success }
    return { errors: json }
  } catch (error) {
    let message = 'Something went wrong. Please, try again or reload the page.'
    if (!res.ok) message += ` (${res.status}: ${res.statusText})`
    else if (error instanceof SyntaxError) message += ' (Bad response)'
    else if (error instanceof Error) message += ` (${error.name}: ${error.message})`

    return { error: message }
  }
}
