import 'components/radio-button';
import 'components/toggle-button';
import 'components/weather-icon';
import 'components/temp-value';

import { WindIcon } from 'components/wind-icon';
import initSwellIcons from 'components/swell-icon';
import { TempValue } from 'components/temp-value';
import tideTable from 'components/tide-table';
import switchUnits from 'components/switch-units';
import { loadTides } from 'utils/tide/tides';
import Units from 'utils/units'; // eslint-disable-line import/no-named-as-default
import DomLoaded from 'utils/dom-loaded';
import PageLoaded from 'utils/page-loaded';
import 'fc-ads/fc-ads.scss';
import init from 'init/application';
import statistics from 'components/statistics';
import forecastTable from 'components/forecast-table';
import langSelector from 'components/lang-selector';
import scrollButton from 'components/scroll-button/scroll-button';
import 'components/carousel/carousel';
import 'components/breakheader';
import 'components/guideheader';
import 'components/seo-section';
import 'components/guide-page';
import 'components/forecast-cta';
import 'components/nearby-points';
import 'components/t-shirt-comp';
import 'components/print-link';
import 'components/join-incentives';
import 'components/signup-prompts';
import 'components/footer';
import 'components/print-header';
import 'components/language-flags';
import 'components/country-flag';
import 'components/star-rating';
import 'components/app-store';
import 'components/app-buttons';
import { Tweets } from 'components/tweets';
import { BreakHeader } from 'components/breakheader';
import { Smartbar } from 'components/smartbar';
import tidesDot from 'components/tides-dot';
import initTooltip from 'components/tooltip';
import resortWebcams from 'components/resort-webcams';
import alertForm from 'components/alert-form';
import { Leaflet } from 'components/leaflet';
import { TidesCountdown } from 'components/tides-countdown';
import { SignUp, Incentive } from 'components/signup/index.tsx';
import { Nearby } from 'components/nearby';
import { MapNodesDebug } from 'components/map-nodes-debug';

import 'static';
import 'init/styles/custom.scss';

DomLoaded(() => {
  init();
  statistics();
  forecastTable();
  langSelector();
  scrollButton();
  initTooltip();
  resortWebcams();
  MapNodesDebug();

  switchUnits();
  loadTides();
  tideTable(window.FCGON || {});
  alertForm();
  WindIcon.init(document, {});
  initSwellIcons(document, Units);
  TempValue.init(document.body);
  Smartbar.init(document.body);

  TidesCountdown.init(document);
  new SignUp();
  Incentive.init(document.body);

  if (document.body.classList.contains('js-units-i')) {
    Units.change({
      temperature: 'Imperial',
      length: 'Imperial',
      speed: 'Imperial',
    });
  }

  const switchSurfUnits = newUnits => {
    const val = newUnits === 'i' ? 'Imperial' : 'Metric';
    Units.change({ temperature: val, length: val, speed: val });
  };
  window.switchSurfUnits = switchSurfUnits;

  Units.onChange(changes => {
    if (!window.unitsConv || !changes) return;

    if (
      changes.temperature === 'Imperial' ||
      changes.length === 'Imperial' ||
      changes.speed === 'Imperial'
    ) {
      window.unitsConv.switchFCUnits('i');
    } else if (
      changes.temperature === 'Metric' ||
      changes.length === 'Metric' ||
      changes.speed === 'Metric'
    ) {
      window.unitsConv.switchFCUnits('m');
    }
  });

  if (window.FCGON && window.FCGON.maps) {
    Leaflet.init(document.body, window.FCGON.maps);
  }

  Nearby.init(document.body);
  Tweets.init(document.body);
  BreakHeader.init(document.body);
});

PageLoaded(() => {
  tidesDot();
  document.body.classList.add('loaded');
});
